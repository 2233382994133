import { AnalyticsService } from 'src/app/services/general/analytics.service';
import { LocalityService } from 'src/app/services/location/locality.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { Component, ElementRef, HostListener, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ModalController, Platform, PickerController, ActionSheetController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as Add2Calendar from 'add2calendar';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';

import { AccountInfoService } from 'src/app/services/account/account-info.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GroupService } from 'src/app/services/group/group.service';
import { VisitorService } from 'src/app/services/visitor/visitor.service';
import { AccountService } from 'src/app/services/account/account.service';
import { LanguageService } from 'src/app/services/general/language.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { LinkService } from 'src/app/services/general/link.service';
import { ErrorService } from 'src/app/services/general/error.service';

import { VisitorGroupComponent } from 'src/app/components/visitor/visitor-group/visitor-group.component';
import { AccountInfo } from 'src/app/interfaces/account';
import { Language, SettingField } from 'src/app/interfaces/database';
import { Group } from 'src/app/interfaces/group';
import { LocationDetail, Theme } from 'src/app/interfaces/general';
import { PageSetting, PageData, VisitorAttachment } from 'src/app/interfaces/visitor';
import { Guest } from 'src/app/interfaces/guest';
import { VisitorGuestTestData } from 'src/app/commons/visitor';
import { DynamicUrl, QrHowVideoUrl } from 'src/app/commons/url';
import { Options } from 'qr-code-styling';
// import SwiperCore, { Autoplay, Swiper, SwiperOptions } from 'swiper';
import { FileService } from 'src/app/services/general/file.service';
import { GpsType, VisitorMenuType } from 'src/app/types/visitor';
import { SeatingSettingService } from 'src/app/services/seating/seating-setting.service';
import { VisitorRealtimeService } from 'src/app/services/visitor/visitor-realtime.service';
import { VisitorSettingService } from 'src/app/services/visitor/visitor-setting.service';
import { VisitorLanguageService } from 'src/app/services/visitor/visitor-language.service';
import { VisitorLogoService } from 'src/app/services/visitor/visitor-logo.service';
import { GpsService } from 'src/app/services/general/gps.service';
import { DateTimeService } from 'src/app/services/general/date-time.service';
import { ThemeList } from 'src/app/commons/theme';
import { DeviceService } from 'src/app/services/device/device.service';

import linkifyHtml from "linkify-html";

import { SafeHtml } from '@angular/platform-browser';

// SwiperCore.use([ Autoplay ]);

/**
 * Visitor page component
 */
@Component({
  selector: 'app-visitor-page',
  templateUrl: './visitor-page.component.html',
  styleUrls: ['./visitor-page.component.scss'],
})
export class VisitorPageComponent implements OnInit, OnDestroy {
  
  visitorMenuType: VisitorMenuType = 'qrcode';

  // attachmentSwiperConfig: SwiperOptions = {
  //   // lazy: {
  //   //   loadPrevNext: true,
  //   //   loadPrevNextAmount: 1,
  //   // },
  //   autoplay: {
  //     delay: 5000,
  //     disableOnInteraction: true,
  //   },
  //   speed: 500,
  //   slidesPerView: 1,
  //   updateOnWindowResize: true,
  //   // // grabCursor: true,
  //   preventClicks: false,
  //   modules: [ Autoplay ]
  // };

  // btnSwiperConfig: SwiperOptions = {
  //   // lazy: {
  //   //   loadPrevNext: true,
  //   //   loadPrevNextAmount: 1,
  //   // },
  //   autoplay: {
  //     delay: 5000,
  //     disableOnInteraction: true,
  //   },
  //   speed: 500,
  //   slidesPerView: 1,
  //   updateOnWindowResize: true,
  //   // // grabCursor: true,
  //   preventClicks: false,
  //   modules: [ Autoplay ]
  // };

  // attachmentSwiper: Swiper;
  // btnSwiper: Swiper;

  @ViewChild('attachmentDiv', { read: ElementRef, static: false }) attachmentDiv: ElementRef;
  // @ViewChild('attachmentOuterDiv', { read: ElementRef, static: false }) attachmentOuterDiv: ElementRef;
  
  @ViewChild('checkinDiv', { static: false }) checkinDiv: ElementRef;

  @ViewChild('qrcodeDiv', { static: false }) qrcodeDiv: ElementRef;
  /**
   * Watch screen resize change
   */
  @HostListener('window:resize', ['$event']) onResize() {
    this.setupQrcodeSize();
    this.setupSlidePerView();
    // this.setupContentHeight();
  }
  /**
   * Watch screen orientation change
   */
  @HostListener('window:orientationchange', ['$event']) onOrientationChange() {
    this.setupQrcodeSize();
    this.setupSlidePerView();
    // this.setupContentHeight();
  }
  
  // @ViewChild('canvas', { static: false }) canvas: ElementRef;

  // @Input() contentHeight: number;
  /**
   * Page mode for actual visitor page, modal for preview
   */
  @Input() pageMode: boolean;
  /**
   * Preview flag
   */
  @Input() preview: boolean;

  @Input() themeMode: boolean;
  /**
   * Account ID
   */
  @Input() accountId: string;
  /**
   * Guest ID
   */
  @Input() guestId: string;
  /**
   * Group ID
   */
  @Input() groupId: string;
  /**
   * Page theme
   */
  @Input() private pageTheme: string;
  /**
   * Page type
   */
  @Input() private pageBackground: string;

  /**
   * Initial state
   */
  @Input() set setReady(ready: boolean) {
    if (ready) {
      this.initialize();
    } else {
      this.unwatch();
    }
  }

  config: Options;

  lbl = {
    loading: '',
    lost: '',
    notFound: '',
    home: '',
    copyright: '',
    groupName: '',
    guestQrcode: '',
    groupQrcode: '',
    groupMemberQrcode: '',
    guestInvitation: '',
    groupInvitation: '',
    groupMemberInvitation: '',
    how: '',
    calendar: '',
    refresh: '',
    date: '',
    month: '',
    year: '',
    guestPass: '',
    checkin: '',
    checked: '',
    qrcode: '',
    status: '',
    checkinTitle: '',
    checkinMsg: '',
    checkinSeating: '',
  };
  /**
   * Loading status
   */
  loading: boolean;
  /**
   * Show error
   */
  showError: boolean;
  /**
   * Guest data
   */
  guest: any;
  /**
   * Guest group data
   */
  group: any;
  /**
   * account Info
   */
  accountInfo: any;

  /**
   * Preview data
   */
  previewData: any;
  /**
   * Page data
   */
  pageData: PageData;

  /**
   * Page setting
   */
  pageSetting: PageSetting = this.visitorSettingService.getDefaultSetting();
  /**
   * Page location
   */
  pageLocation: LocationDetail;
  /**
   * Language
   */
  language: Language;
  /**
   * Year
   */
  year = 2021;
  /**
   * Header height
   */
  // headerHeight = 44;
  /**
   * Footer height
   */
  // footerHeight = 44;

  hybrid: boolean;
  ios: boolean;

  translation: any;

  showHeader: boolean;

  showRefreshBtn: boolean;
  /**
   * Page info
   */
  private pageInfo: any;
  /**
   * Page language list
   */
  pageLangList: Language[];

  qrcode: any;

  checkinStatus: boolean;

  stopGif: boolean;

  backgroundId: string;
  themeId: string;
  title: string;
  title2: string;
  url: string;
  logoUrl: string;
  customLogo: boolean;
  logoWidth: number;
  groupName: string;
  groupType: string;
  groupMemberType: string;
  pax: string;
  showSeating: boolean;
  seating: string;
  seatingTypeName: string;
  guestSeating: string;
  session: string;
  timestamp: number;
  date: string;
  startTime: string;
  endTime: string;
  timezone: string;
  cocktail: string;
  venue: string;
  hall: string;
  address: string;
  greeting: string;
  msg: string | SafeHtml;
  remark: string;
  itinerary: string;
  btnOutline: string;
  btnColor: { [type: string]: string };
  // checkinDivWidth: number;
  gpsMaxWidth: number;

  menu: string;
  floorplan: string;
  attachment: string;

  btnSlidePerView: number;
  attachmentSlidePerView: number;

  appleMap: boolean;
  googleMap: boolean;
  grab: boolean;
  uber: boolean;
  waze: boolean;

  // height: string;


  // private attachmentSwiper: Swiper;
  // private attachmentSlidePerView: number;

  private seatingType: SettingField;
  private seatingTypeOther: string;

  private logo: string;
  private logoSize: number;
  /**
   * Account info subscription
   */
  private accountInfoSubscription: Subscription;
  /**
   * Page data subscription
   */
  private pageDataSubscription: Subscription;

  private pageLangListSubscription: Subscription;

  private translationSubscription: Subscription;

  private checkinStatusSubscription: Subscription;

  /**
   * Constructor
   * @param platform Platform
   * @param modalController Modal controller
   * @param pickerController Picker controller
   * @param fns Firestore cloud function
   * @param translate Translate service
   * @param languageService Device language service
   * @param accountService Account service
   * @param visitorService Page setting service
   * @param guestListService Guest list service
   * @param groupService Guest group service
   * @param functionService Function service
   */
  constructor(
    private ngZone: NgZone,
    private platform: Platform,
    private modalController: ModalController,
    private pickerController: PickerController,
    private calendar: Calendar,
    private fns: AngularFireFunctions,
    private translate: TranslateService,
    private analyticsService: AnalyticsService,
    private languageService: LanguageService,
    private dateTimeService: DateTimeService,
    private accountService: AccountService,
    private deviceService: DeviceService,
    private accountInfoService: AccountInfoService,
    private seatingSettingService: SeatingSettingService,
    private visitorService: VisitorService,
    private visitorSettingService: VisitorSettingService,
    private visitorLanguageService: VisitorLanguageService,
    private visitorRealtimeService: VisitorRealtimeService,
    private visitorLogoService: VisitorLogoService,
    private guestService: GuestService,
    private groupService: GroupService,
    private linkService: LinkService,
    private functionService: FunctionService,
    private localityService: LocalityService,
    private gpsService: GpsService,
    private fileService: FileService,
    private errorService: ErrorService,
    private popupService: PopupService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.unwatch();
  }

  /**
   * Initialize for modal mode
   */
  ionViewWillEnter() {
    this.initialize();
  }

  /**
   * Unwatch
   */
  ionViewWillLeave() {
    this.unwatch();
  }

  /**
   * Initialize
   */
  async initialize() {
    await this.platform.ready();
    const date = new Date();
    this.year = date.getFullYear();
    this.loading = true;
    this.showError = false;

    this.hybrid = this.platform.is('hybrid');
    this.ios = this.platform.is('ios');

    this.logo = '';
    this.logoSize = 0;
    this.checkinStatus = false;

    if (this.preview) {
      this.language = { ...this.languageService.getDeviceLanguage() };
      this.setupPreview();
    } else {
      this.language = { ...await this.languageService.readDeviceLanguage() };
      this.setupPage();
    }
    this.setupLbl();
    if (!this.themeMode && (this.preview || (this.pageMode && this.hybrid))) {
      this.ngZone.run(() => {
        this.showHeader = true;
      });
    } else {
      this.showHeader = false;
    }
    // this.setupContentHeight();
    this.setupQrcodeSize();
    this.setupSlidePerView();
    window.scrollTo(0, 1);
  }

  setupLbl() {
    this.lbl = {
      loading: this.getTranslate('VISITOR.lbl.loading'),
      lost: this.getTranslate('VISITOR.lbl.got_lost'),
      notFound: this.getTranslate('VISITOR.lbl.not_found'),
      home: this.getTranslate('VISITOR.btn.home'),
      copyright: this.translate.instant('VISITOR.lbl.copyright').replace('{{ year }}', this.year),
      groupName: this.getVariable(this.getTranslate('QRCODE.lbl.group_name')),
      guestQrcode: this.getTranslate('QRCODE.lbl.guest_qrcode'),
      groupQrcode: this.getTranslate('QRCODE.lbl.group_qrcode'),
      groupMemberQrcode: this.getTranslate('QRCODE.lbl.guest_group_qrcode'),
      guestInvitation: this.getTranslate('QRCODE.lbl.guest_invitation'),
      groupInvitation: this.getTranslate('QRCODE.lbl.group_invitation'),
      groupMemberInvitation: this.getTranslate('QRCODE.lbl.guest_group_invitation'),
      how: this.getTranslate('VISITOR.btn.how'),
      calendar: this.getTranslate('VISITOR.setting.calendar.title'),
      refresh: this.getTranslate('BTN.refresh'),
      date: this.getTranslate('DATE.lbl.date_short'),
      month: this.getTranslate('DATE.lbl.month'),
      year: this.getTranslate('DATE.lbl.year'),
      guestPass: this.getTranslate('VISITOR.variable.guestPass'),
      checkin: this.getTranslate('MODULE.list.checkin'),
      checked: this.getTranslate('LIST.checkin.attended'),
      qrcode: this.getTranslate('MODULE.list.qrcode'),
      status: this.getTranslate('BLAST.usage.status.title'),
      checkinTitle: this.getTranslate('VISITOR.page.checkin.title'),
      checkinMsg: this.getTranslate('VISITOR.page.checkin.msg'),
      checkinSeating: this.getTranslate('VISITOR.page.checkin.seating'),
    };
    if (this.accountId === 'PVbl03YZoAUJI9pLIrHs') {
      this.lbl.loading = this.getTranslate('LOADING.loading');
    }
  }

  setupValue() {
    this.backgroundId = this.getThemeBackground();
    this.themeId = this.getThemeId();
    this.title = this.getTitle();

    this.title2 = this.getTitle2();
    this.url = this.getGuestUrl();
    this.logoUrl = this.getLogo();
    this.logoWidth = this.getLogoWidth();
    this.groupName = this.getGroupName();
    this.groupType = this.getGroupType();
    this.groupMemberType = this.getGroupMemberType();
    this.pax = this.getPax();
    this.showSeating = this.getShowSeating();
    this.seating = this.getSeating();
    this.seatingTypeName = this.getSeatingTypeName();
    this.guestSeating = this.getVariable('[GUEST_SEATING]');
    this.session = this.getSession();
    this.timestamp = this.getEventTimestamp();
    this.date = this.getDate();
    this.startTime = this.getStartTime();
    this.endTime = this.getEndTime();
    this.timezone = this.getTimezone();
    this.cocktail = this.getCocktail();
    this.venue = this.getVenue();
    this.hall = this.getHall();
    this.address = this.getAddress();
    this.greeting = this.getGreeting();

    this.msg = this.getMsg();

    this.remark = this.getRemark();
    this.itinerary = this.getItinerary();
    this.btnOutline = this.getBtnOutline();
    this.btnColor = {
      'checkin': this.getBtnColor('checkin'),
      'how': this.getBtnColor('how'),
      'attachment': this.getBtnColor('attachment'),
    };
    // this.checkinDivWidth = this.calculateWidth();
    this.gpsMaxWidth = this.calculateMaxWidth();

    this.menu = this.getMenu();
    this.attachment = this.getAttachment();
    this.floorplan = this.getFloorplan();
  }

  setupSlidePerView() {
    const width = this.getContainerWidth();
    if (width) {
      this.btnSlidePerView = this.getSlidePerView('btn');
      this.attachmentSlidePerView = this.getSlidePerView('attachment');
    } else {
      setTimeout(() => {
        this.setupSlidePerView();
      }, 500);
    }
  }

  setupPreview() {
    this.accountInfo = this.accountInfoService.accountInfo;
    if (!this.guestId && !this.groupId) {
      this.guest = VisitorGuestTestData;
      this.guest.name = this.getTranslate('GUEST.lbl.guest');
      this.guestId = this.guest.guestId;
    } else if (this.guestId) {
      this.guest = this.guestService.getGuest(this.guestId);
      if (this.guest) {
        if (this.guest?.groupId) {
          const group = this.groupService.getGroup(this.guest.groupId);
          this.guest.group = group;
          if (group?.memberList?.length) {
            this.guest.pax = group.memberList.length;
            const guestList = this.guestService.getGuestListById(group?.memberList);
            this.guest.group.guestList = guestList;
          }
        }
        if (!this.guest.pax) {
          this.guest.pax = 1;
        }
        this.watchPageLangList();
      }
    } else if (this.groupId) {
      this.group = this.groupService.getGroup(this.groupId);
      const guestList = this.guestService.getGuestListById(this.group?.memberList);
      this.group.guestList = guestList;
      this.watchPageLangList();
    }
    
    if (!this.accountId && this.accountService?.accountId) {
      this.accountId = this.accountService.accountId;
    }

    this.watchAccountInfo();
    this.watchPageData();

    if (!this.accountInfo || this.functionService.isEmpty(this.accountInfo)) {
      this.showErrorPage();
    } else {
      this.setupLanguage();
      this.generateQrcode();
    }
    // this.setupValue();
    this.loading = false;
  }

  setupQrcodeSize() {
    if (this.qrcodeDiv?.nativeElement?.offsetWidth) {
      let width = this.qrcodeDiv.nativeElement.offsetWidth * 0.9;
      if (width > 300) {
        width = 300;
      } else if (width < 200) {
        width = this.qrcodeDiv.nativeElement.offsetWidth;
      }
      if (!this.config || this.config?.width !== width) {
        try {
          this.config = null;
          setTimeout(() => {
            this.config = this.getDefaultConfig();
            this.config.width = width;
            this.config.height = width;
          }, 20);
        } catch(err: any) {
          console.log(err);
        }
      }
    } else {
      setTimeout(() => {
        this.setupQrcodeSize();
      }, 100);
    }
  }

  // setupContentHeight() {
  //   if (this.showHeader) {
  //     this.height = 'calc(100% - ' + (this.headerHeight + this.footerHeight) + 'px)';
  //   } else if (this.themeMode) {
  //     this.height = '100%';
  //   } else if (this.contentHeight) {
  //     this.height = 'calc(' + this.contentHeight + 'px - ' + this.footerHeight + 'px)';
  //   } else {
  //     this.height = 'calc(100vh - ' + this.footerHeight + 'px)';
  //   }
  // }

  showErrorPage() {
    this.showError = true;
    this.loading = false;
    this.pageLangList = [ ...this.languageService.languageList ];
  }

  unwatch() {
    this.unwatchAccountInfo();
    this.unwatchPageData();
    this.unwatchPageLangList();
    this.unwatchTranslation();
    this.visitorRealtimeService.unwatchRtdb();
    this.unwatchCheckinStatus();
  }

  /**
   * Watch Account Info
   */
  async watchAccountInfo() {
    if (!this.accountInfoSubscription) {
      this.accountInfoSubscription = this.accountInfoService.observableAccountInfo.subscribe((accountInfo: AccountInfo) => {
        this.accountInfo = accountInfo;
        this.setupLocation();
      });
    }
    
  }

  /**
   * Unwatch Account Info
   */
  async unwatchAccountInfo() {
    if (this.accountInfoSubscription) {
      this.accountInfoSubscription.unsubscribe();
      this.accountInfoSubscription = null;
    }
  }

  /**
   * Watch page data
   */
  async watchPageData() {
    if (!this.pageDataSubscription) {
      this.pageDataSubscription = this.visitorService.observablePageData.subscribe((pageData: any) => {
        this.pageData = pageData;
        this.setupPageData();
      });
    }
    
  }

  /**
   * Unwatch page data
   */
  async unwatchPageData() {
    if (this.pageDataSubscription) {
      this.pageDataSubscription.unsubscribe();
      this.pageDataSubscription = null;
    }
  }

  /**
   * Watch page language list
   */
  async watchPageLangList() {
    if (!this.pageLangListSubscription) {
      this.pageLangListSubscription = this.visitorLanguageService.observablePageLangList.subscribe(() => {
        this.pageLangList = this.visitorLanguageService.getPangeLangList();
        this.setupLanguage();
      });
    }
    
  }

  /**
   * Unwatch page language list
   */
  async unwatchPageLangList() {
    if (this.pageLangListSubscription) {
      this.pageLangListSubscription.unsubscribe();
      this.pageLangListSubscription = null;
    }
  }

  async watchRtdb() {
    if (this.accountId && (this.guestId || this.groupId)) {
      await this.visitorRealtimeService.watchRtdb(this.accountId, this.guestId, this.groupId);
      await this.watchCheckinStatus();
    }
  }

  async watchCheckinStatus() {
    if (!this.checkinStatusSubscription) {
      this.checkinStatusSubscription = this.visitorRealtimeService.observableCheckinStatus.subscribe((checkinStatus: boolean) => {
        if (this.checkinStatus !== checkinStatus) {
          this.updateCheckinStatus(checkinStatus);
        }
      });
    }
    
  }

  async unwatchCheckinStatus() {
    if (this.checkinStatusSubscription) {
      this.checkinStatusSubscription.unsubscribe();
      this.checkinStatusSubscription = null;
    }
  }

  setupAttachmentSwiper(event: any) {
    // this.attachmentSwiper = event?.target?.swiper;
  }

  /**
   * open group modal
   */
  openGroupModal() {
    if (this.pageSetting?.groupMember) {
      if (this.group && !this.themeMode) {
        if (this.group?.memberList) {
          this.presentVistorGroupModal(this.group, this.group?.memberList);
        } else if (this.group?.guestList) {
          this.presentVistorGroupModal(this.group, this.group?.guestList);
        }
      } else if (this.guest?.group) {
        this.presentVistorGroupModal(this.guest.group, this.guest.group?.guestList);
      }
    }
  }

  /**
   * Present visitor group modal
   * @param group group
   * @param guestList guest list
   */
  async presentVistorGroupModal(group?: Group, guestList?: Guest[]) {
    if (group && guestList) {
      const modal = await this.modalController.create({
        component: VisitorGroupComponent,
        cssClass: 'modal-backdrop',
        componentProps: {
          group,
          guestList,
          showGuestSeating: this.pageSetting?.seatingBeforeCheckin || this.checkinStatus ? true : false,
          seatingTypeName: this.getSeatingTypeName(),
        }
      });
      modal.present();
    }
  }

  /**
   * Dismiss visitor page modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss(); }
    }
  }

  /**
   * Go back
   */
  back() {
    if (this.preview) {
      this.dismissModal();
    } else {
      this.linkService.goMainPage();
    }
  }

  /**
   * Setup language
   */
  setupLanguage() {
    // console.log(this.pageLangList);
    if (this.pageLangList?.length) {
      if (!this.language?.code || this.pageLangList?.findIndex((language: Language) => language.code === this.language.code) === -1) {
        const langCode = this.languageService.getDeviceLanguage()?.code ? this.languageService.getDeviceLanguage().code : 'en';
        const index = this.pageLangList?.findIndex((x: Language) => x.code === langCode);
        if (index !== -1) {
          this.language = { ...this.pageLangList[index] };
        } else {
          this.language = { ...this.pageLangList[0] };
        }
      }
    }
    this.getTranslation();
  }

  /**
   * Setup page data
   */
  setupPageData() {
    if (this.previewData?.languageList) {
      this.pageLangList = this.previewData.languageList;
    } else if (this.pageData?.languageList) {
      this.pageLangList = this.pageData.languageList;
    }

    if (this.previewData?.language) {
      this.language = { ...this.languageService.getLanguageByCode(this.previewData.language) };
    }

    if (!this.themeMode) {
      if (this.previewData?.setting) {
        this.setupPageSetting(this.previewData.setting);
      } else if (this.pageData?.setting) {
        this.setupPageSetting(this.pageData.setting);
      }
    }

    if (!this.pageSetting || this.themeMode) {
      this.setupPageSetting();
    }

    if (this.previewData?.info) {
      this.pageInfo = this.previewData.info;
    } else if (this.pageData?.info) {
      this.pageInfo = this.pageData.info;
    }

    if (this.previewData?.themeId) {
      this.pageTheme = this.previewData.themeId;
    } else if (!this.themeMode && this.pageData?.themeId && this.pageTheme !== this.pageData.themeId) {
      this.pageTheme = this.pageData.themeId;
    }

    // if (this.previewData?.themeBackground) {
    //   this.pageBackground = this.previewData.themeBackground;
    // } else if (!this.themeMode && this.pageData?.themeBackground && this.pageBackground !== this.pageData.themeBackground) {
    //   this.pageBackground = this.pageData.themeBackground;
    // }

    this.setupLanguage();
    this.watchRtdb();
  }

  setupLocation() {
    if (this.previewData?.location) {
      this.pageLocation = this.previewData.location;
    } else if (this.accountInfoService?.accountInfo?.location) {
      this.pageLocation = this.accountInfoService.accountInfo.location;
    }
  }

  /**
   * Setup page
   */
  setupPage() {
    if (this.accountId) {
      this.retrieveVisitorInfo(this.accountId, this.guestId, this.groupId);
    } else {
      this.showErrorPage();
    }
  }

  setupPageSetting(pageSetting?: PageSetting) {
    if (!pageSetting) {
      pageSetting = this.visitorSettingService.getDefaultSetting();
    } else if (pageSetting?.backgroundUrl && this.functionService.isUndefined(pageSetting?.qrcode)) {
      const setting = this.visitorSettingService.getDefaultSetting();
      setting.backgroundUrl = pageSetting.backgroundUrl;
    }

    this.pageSetting = pageSetting;
    this.showSeating = this.getShowSeating();
    this.setupGpsCheck();
  }

  /**
   * Retrieve visitor web info from cloud function
   * @param accountId Account ID
   * @param guestId Guest ID
   * @param groupId Group ID
   */
  retrieveVisitorInfo(accountId: string, guestId?: string, groupId?: string, checkCount?: number) {
    // if (this.onlineService.online) {
      this.fns.httpsCallable('retrieveVisitorWebInfo')({
        accountId,
        guestId,
        groupId
      }).toPromise().then((response: any) => {
        if (response) {
          if (response.accountInfo) {
            this.accountInfo = response.accountInfo;
            if (response.pageSetting && !this.functionService.isEmpty(response.pageSetting)) {
              this.setupPageSetting(response.pageSetting);
            } else {
              this.setupPageSetting();
            }
            if (response.pageInfo && !this.functionService.isEmpty(response.pageInfo)) {
              this.pageInfo = response.pageInfo;
            }
            if (response.pageLocation && !this.functionService.isEmpty(response.pageLocation)) {
              this.pageLocation = response.pageLocation;
            }
            if (response?.pageTheme) {
              this.pageTheme = response.pageTheme;
            }
            if (response?.pageBackground) {
              this.pageBackground = response.pageBackground;
            }
            if (response.guest && !this.functionService.isEmpty(response.guest)) {
              this.guest = response.guest;
            }
            if (response.group && !this.functionService.isEmpty(response.group)) {
              this.group = response.group;
            }
            if (response?.pageLangList) {
              this.pageLangList = response.pageLangList;
            }
            if (response?.logo) {
              this.logo = response.logo;
            }
            if (response?.logoSize) {
              this.logoSize = response.logoSize;
            }
            if (response?.seatingType?.value) {
              if (response?.seatingType.value === 'others' && !response?.seatingType?.custom && response?.seatingTypeOther) {
                this.seatingTypeOther = response.seatingTypeOther;
              } else {
                this.seatingType = response?.seatingType;
              }
            }
            this.setupLanguage();
            this.setupGpsCheck();
            this.watchRtdb();
            // this.generateQrcode();
            this.analyticsService.setVisitor(this.accountId, this.guestId, this.groupId);
          }
        }
        if (!this.accountInfo || this.functionService.isEmpty(this.accountInfo)) {
          this.showErrorPage();
        }
        this.loading = false;
      }).catch((err: any) => {
        console.error(err);
        if (err?.toString()?.indexOf('FirebaseError: internal') !== -1) {
          setTimeout(()  => {
            if (!checkCount) {
              checkCount = 0;
            }
            if (checkCount < 3) {
              this.retrieveVisitorInfo(accountId, guestId, groupId, checkCount++);
            } else {
              this.showRefreshBtn = true;
              this.showErrorPage();
            }
          }, 1500);
        } else {
          this.errorService.logError(err);
          this.showRefreshBtn = true;
          this.showErrorPage();
        }
      });
  }

  validateGuest(): boolean {
    if (this.group?.groupId) {
      if (!this.group?.memberList?.length && !this.group?.guestList?.length) {
        return false;
      }
    } else if (!this.group?.groupId && !this.guest?.guestId) {
      return false;
    }
    return true;
  }

  async webCheckin() {
    const eventTime = this.getEventTimestamp();
    const startTime = this.dateTimeService.adjustTime(eventTime, {
      hours: this.pageSetting?.webCheckinStartHour ? -this.pageSetting.webCheckinStartHour : 0,
      minutes: this.pageSetting?.webCheckinStartMinute ?  -this.pageSetting.webCheckinStartMinute : 0,
    });
    const endTime = this.dateTimeService.adjustTime(eventTime, {
      hours: this.pageSetting?.webCheckinEndHour ? this.pageSetting.webCheckinEndHour : 0,
      minutes: this.pageSetting?.webCheckinEndMinute ?  this.pageSetting.webCheckinEndMinute : 0,
    });

    if (await this.validateCheckin(startTime, endTime)) {
      if (this.preview || this.guestId === 'test' || this.groupId === 'test') {
        this.updateCheckinStatus(true);
      } else {
        const msg = this.translate.instant('VISITOR.webCheckin.msg.confirm');
        const title = this.translate.instant('VISITOR.setting.webCheckin.title');
        const confirm = await this.popupService.presentConfirm(msg, '', title, '', '', '', false, '', [], false, [], true); 
        confirm.onDidDismiss().then(async (result: any) => {
          if (result?.data?.confirm) {
            await this.popupService.presentLoading();
            await this.fns.httpsCallable('webCheckinCall')({
              accountId: this.accountId,
              guestId: this.guestId,
              groupId: this.groupId,
              uuid: this.deviceService.uuid,
            }).toPromise().then(async (response: any) => {
              await this.popupService.dismissLoading();
              if (response?.success) {
                this.analyticsService.webCheckin(this.accountId);
                this.updateCheckinStatus(true);
              } else {
                if (response?.error === 'start') {
                  await this.promptCheckinError('start', startTime);
                } else if (response?.error === 'end') {
                  await this.promptCheckinError('end', endTime);
                } else if (response?.error === 'invalid') {
                  await this.promptCheckinError('invalid', endTime);
                } else {
                  await this.promptCheckinError();
                }
              }
            }).catch(async (err: any) => {
              this.errorService.logError(err);
              await this.popupService.dismissLoading();
              await this.promptCheckinError();
            });
          }
        });
      }
    }
  }

  async validateCheckin(startTime: Date, endTime: Date) {
    if (this.pageSetting?.webCheckin) {
      const now = new Date();
      if (now < startTime) {
        await this.promptCheckinError('start', startTime);
        return false;
      } else if (now > endTime) {
        await this.promptCheckinError('end', endTime);
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  async promptCheckinError(type?: string, time?: Date) {
    if (type === 'start' && time) {
      const date = this.dateTimeService.format(time, 'do MMM yyyy, EEEE h:mm a');
      const msg = this.translate.instant('VISITOR.webCheckin.msg.start', { date });
      await this.popupService.presentAlert(msg);
    } else if (type === 'end' && time) {
      const date = this.dateTimeService.format(time, 'do MMM yyyy, EEEE h:mm a');
      const msg = this.translate.instant('VISITOR.webCheckin.msg.end', { date });
      await this.popupService.presentAlert(msg);
    } else if (type === 'invalid') {
      const msg = this.translate.instant('VISITOR.webCheckin.msg.invalid');
      await this.popupService.presentAlert(msg);
    } else {
      const msg = this.translate.instant('MSG.action_error');
      await this.popupService.presentAlert(msg);
    }
  }

  async getTranslation() {
    if (this.language?.code) {
      await this.unwatchTranslation();
      const res = this.languageService.getTranslation(this.language.code);
      this.translation = res;

      if (res?.VISITOR?.lbl?.loading) {
        this.lbl.loading = res.VISITOR.lbl.loading;
        if (this.accountId === 'PVbl03YZoAUJI9pLIrHs') {
          this.lbl.loading = res.LOADING.loading;
        }
      }
      if (res?.VISITOR?.lbl?.got_lost) {
        this.lbl.lost = res.VISITOR.lbl.got_lost;
      }
      if (res?.VISITOR?.lbl?.not_found) {
        this.lbl.notFound = res.VISITOR.lbl.not_found;
      }
      if (res?.VISITOR?.btn?.home) {
        this.lbl.home = res.VISITOR.btn.home;
      }
      if (res?.VISITOR?.lbl?.copyright) {
        this.lbl.copyright = res.VISITOR.lbl.copyright.replace('{{ year }}', this.year);
      }
      if (res?.QRCODE?.lbl?.guest_qrcode) {
        this.lbl.guestQrcode = res.QRCODE.lbl.guest_qrcode;
      }
      if (res?.QRCODE?.lbl?.group_qrcode) {
        this.lbl.groupQrcode = res.QRCODE.lbl.group_qrcode;
      }
      if (res?.QRCODE?.lbl?.guest_group_qrcode) {
        this.lbl.groupMemberQrcode = res.QRCODE.lbl.guest_group_qrcode;
      }
      if (res?.QRCODE?.lbl?.guest_invitation) {
        this.lbl.guestInvitation = res.QRCODE.lbl.gues_invitation;
      }
      if (res?.QRCODE?.lbl?.group_invitation) {
        this.lbl.groupInvitation = res.QRCODE.lbl.group_invitation;
      }
      if (res?.QRCODE?.lbl?.guest_group_invitation) {
        this.lbl.groupMemberInvitation = res.QRCODE.lbl.guest_group_invitation;
      }
      if (res?.VISITOR?.btn?.how) {
        this.lbl.how = res.VISITOR.btn.how;
      }
      if (res?.VISITOR?.setting?.calendar?.title) {
        this.lbl.calendar = res.VISITOR.setting.calendar.title;
      }
      if (res?.BTN?.refresh) {
        this.lbl.refresh = res.BTN.refresh;
      }
      if (res?.DATE?.lbl?.date) {
        this.lbl.date = res.DATE.lbl.date_short;
      }
      if (res?.DATE?.lbl?.month) {
        this.lbl.month = res.DATE.lbl.month;
      }
      if (res?.DATE?.lbl?.year) {
        this.lbl.year = res.DATE.lbl.year;
      }
      if (res?.VISITOR?.variable?.guestPass) {
        this.lbl.guestPass = res.VISITOR.variable.guestPass;
      }
      if (res?.MODULE?.list?.checkin) {
        this.lbl.checkin = res.MODULE.list.checkin;
      }
      if (res?.LIST?.checkin?.attended) {
        this.lbl.checked = res.LIST.checkin.attended;
      }

      if (res?.VISITOR?.page?.checkin?.seating) {
        this.lbl.checkinSeating = res.VISITOR.page.checkin.seating.replace('{{ seating }}', this.getSeatingTypeName());
      }

      // const translate = this.functionService.cloneDeep(this.translate);
      // this.translationSubscription = translate.getTranslation(this.language.code).subscribe((res: any) => {
      //   this.translation = res;
      //   if (res?.VISITOR?.lbl?.loading) {
      //     this.lbl.loading = res.VISITOR.lbl.loading;
      //   }
      //   if (res?.VISITOR?.lbl?.got_lost) {
      //     this.lbl.lost = res.VISITOR.lbl.got_lost;
      //   }
      //   if (res?.VISITOR?.lbl?.not_found) {
      //     this.lbl.notFound = res.VISITOR.lbl.not_found;
      //   }
      //   if (res?.VISITOR?.btn?.home) {
      //     this.lbl.home = res.VISITOR.btn.home;
      //   }
      //   if (res?.VISITOR?.lbl?.copyright) {
      //     this.lbl.copyright = res.VISITOR.lbl.copyright.replace('{{ year }}', this.year);
      //   }
      //   if (res?.QRCODE?.lbl?.guest_qrcode) {
      //     this.lbl.guestQrcode = res.QRCODE.lbl.guest_qrcode;
      //   }
      //   if (res?.QRCODE?.lbl?.group_qrcode) {
      //     this.lbl.groupQrcode = res.QRCODE.lbl.group_qrcode;
      //   }
      //   if (res?.QRCODE?.lbl?.guest_group_qrcode) {
      //     this.lbl.groupMemberQrcode = res.QRCODE.lbl.guest_group_qrcode;
      //   }
      //   if (res?.QRCODE?.lbl?.guest_invitation) {
      //     this.lbl.guestInvitation = res.QRCODE.lbl.gues_invitation;
      //   }
      //   if (res?.QRCODE?.lbl?.group_invitation) {
      //     this.lbl.groupInvitation = res.QRCODE.lbl.group_invitation;
      //   }
      //   if (res?.QRCODE?.lbl?.guest_group_invitation) {
      //     this.lbl.groupMemberInvitation = res.QRCODE.lbl.guest_group_invitation;
      //   }
      //   if (res?.VISITOR?.btn?.how) {
      //     this.lbl.how = res.VISITOR.btn.how;
      //   }
      //   if (res?.VISITOR?.setting?.calendar?.title) {
      //     this.lbl.calendar = res.VISITOR.setting.calendar.title;
      //   }
      //   if (res?.BTN?.refresh) {
      //     this.lbl.refresh = res.BTN.refresh;
      //   }
      //   if (res?.DATE?.lbl?.date) {
      //     this.lbl.date = res.DATE.lbl.date_short;
      //   }
      //   if (res?.DATE?.lbl?.month) {
      //     this.lbl.month = res.DATE.lbl.month;
      //   }
      //   if (res?.DATE?.lbl?.year) {
      //     this.lbl.year = res.DATE.lbl.year;
      //   }
      //   if (res?.VISITOR?.variable?.guestPass) {
      //     this.lbl.guestPass = res.VISITOR.variable.guestPass;
      //   }
      // });
      this.setupLbl();
      this.setupValue();
    }
  }

  async unwatchTranslation() {
    if (this.translationSubscription) {
      this.translationSubscription.unsubscribe();
      this.translationSubscription = null;
    }
  }

  getTranslate(variable: string) {
    let result: string;
    if (this.translation) {
      if (variable === 'VISITOR.variable.seating' && this.translation?.VISITOR?.variable?.seating) {
        result = this.translation.VISITOR.variable.seating.replace('{{ seating }}', this.getSeatingTypeName());
      } else if (variable === 'VISITOR.variable.pax' && this.translation?.VISITOR?.variable?.pax) {
        result = this.translation.VISITOR.variable.pax;
      } else if (variable === 'VISITOR.variable.session' && this.translation?.VISITOR?.variable?.session) {
        result = this.translation.VISITOR.variable.session;
      } else if (variable === 'VISITOR.lbl.copyright' && this.translation?.VISITOR?.lbl?.copyright) {
        result = this.translation.VISITOR.lbl.copyright.replace('{{ year }}', this.year);
      } else if (variable === 'DATE.lbl.date_short' && this.translation?.DATE?.lbl?.date) {
        result = this.translation.DATE.lbl.date_short;
      } else if (variable === 'DATE.lbl.month' && this.translation?.DATE?.lbl?.month) {
        result = this.translation.DATE.lbl.month;
      } else if (variable === 'DATE.lbl.year' && this.translation?.DATE?.lbl?.year) {
        result = this.translation.DATE.lbl.year;
      } else if (variable === 'QRCODE.lbl.group_qrcode' && this.translation?.QRCODE?.lbl?.group_qrcode) {
        result = this.translation.QRCODE.lbl.group_qrcode;
      } else if (variable === 'QRCODE.lbl.guest_group_qrcode' && this.translation?.QRCODE?.lbl?.guest_group_qrcode) {
        result = this.translation.QRCODE.lbl.guest_group_qrcode;
      } else if (variable === 'VISITOR.setting.attachment.floorplan.title' && this.translation?.VISITOR?.setting?.attachment?.floorplan?.title) {
        result = this.translation.VISITOR.setting.attachment.floorplan.title;
      } else if (variable === 'VISITOR.setting.attachment.menu.title' && this.translation?.VISITOR?.setting?.attachment?.menu?.title) {
        result = this.translation.VISITOR.setting.attachment.menu.title;
      } else if (variable === 'VISITOR.setting.attachment.title' && this.translation?.VISITOR?.setting?.attachment?.title) {
        result = this.translation.VISITOR.setting.attachment.title;
      } else if (variable === 'MODULE.list.qrcode' && this.translation?.MODULE?.list?.qrcode) {
        result = this.translation.MODULE.list.qrcode;
      } else if (variable === 'BLAST.usage.status.title' && this.translation?.BLAST?.usage?.status?.title) {
        result = this.translation.BLAST.usage.status.title;
      } else if (variable === 'VISITOR.page.checkin.title' && this.translation?.VISITOR?.page?.checkin?.title) {
        result = this.translation.VISITOR.page.checkin.title;
      } else if (variable === 'VISITOR.page.checkin.msg' && this.translation?.VISITOR?.page?.checkin?.msg) {
        result = this.translation.VISITOR.page.checkin.msg;
      } else if (variable === 'VISITOR.page.checkin.seating' && this.translation?.VISITOR?.page?.checkin?.seating) {
        result = this.translation.VISITOR.page.checkin.seating.replace('{{ seating }}', this.getSeatingTypeName());
      }
    }
    if (!result) {
      result = this.translate.instant(variable) !== variable ? this.translate.instant(variable) : '';
    }
    return result;
  }

  getGroupName(): string {
    let result = null;
    if (this.preview && this.language?.code && this.previewData?.info?.[this.language.code]?.groupName) {
      result = this.previewData.info[this.language.code].groupName;
    } else {
      if (this.pageInfo) {
        if (this.language?.code && this.pageInfo?.[this.language.code]?.groupName) {
          result = this.pageInfo[this.language.code].groupName;
        }
      }
    }
    if (this.functionService.isUndefined(result) || this.themeMode || result === 'VISITOR.variable.groupName') {
      result = this.getTranslate('VISITOR.variable.groupName');
    }

    if (result) {
      result = this.getVariable(result);
    }
    return result;
  }

  getGroupType(): string {
    let result = null;
    if (this.preview && this.language?.code && this.previewData?.info?.[this.language.code]?.groupType) {
      result = this.previewData.info[this.language.code].groupType;
    } else {
      if (this.pageInfo) {
        if (this.language?.code && this.pageInfo?.[this.language.code]?.groupType) {
          result = this.pageInfo[this.language.code].groupType;
        }
      }
    }
    if (this.functionService.isUndefined(result) || this.themeMode) {
      result = this.getTranslate('QRCODE.lbl.group_qrcode');
    }
    return result;
  }

  getGroupMemberType(): string {
    let result = null;
    if (this.preview && this.language?.code && this.previewData?.info?.[this.language.code]?.groupMemberType) {
      result = this.previewData.info[this.language.code].groupMemberType;
    } else {
      if (this.pageInfo) {
        if (this.language?.code && this.pageInfo?.[this.language.code]?.groupMemberType) {
          result = this.pageInfo[this.language.code].groupMemberType;
        }
      }
    }
    if (this.functionService.isUndefined(result) || this.themeMode) {
      result = this.getTranslate('QRCODE.lbl.guest_group_qrcode');
    }
    return result;
  }

  /**
   * Get account title.
   * @returns account title
   */
  getTitle(): string {
    let result = null;
    if (this.preview && this.themeMode) {
      result = this.getTranslate('VISITOR.theme.title');
    } else if (this.preview && this.language?.code && this.previewData?.info?.[this.language.code]?.title) {
      result = this.previewData.info[this.language.code].title;
    } else {
      if (this.pageInfo) {
        if (this.language?.code && this.pageInfo?.[this.language.code]) {
          result = this.pageInfo[this.language.code]?.title;
        }
      }
      if (this.functionService.isUndefined(result) || this.themeMode) {
        if (this.accountInfo?.title?.value) {
          result = this.accountInfo.title.value;
        }
      }
    }
    if (result) {
      result = this.getVariable(result);
    }
    return result;
  }

  /**
   * Get account title line 2, in Visitor page info, line 2.
   * @returns account title
   */
  getTitle2(): string {
    let result = null;
    if (this.preview && this.themeMode) {
    } else if (this.preview && this.language?.code && this.previewData?.info?.[this.language.code]?.title2) {
       result = this.previewData.info[this.language.code].title2;
    } else {
      if (this.pageInfo) {
        if (this.language?.code && this.pageInfo?.[this.language.code]) {
          result = this.pageInfo[this.language.code]?.title2;
        }
      }
      if (this.functionService.isUndefined(result) || this.themeMode) {
        if (this.accountInfo?.title2?.value) {
          result = this.accountInfo.title2.value;
        }
      }
    }
    return result;
  }

  getEventTimestamp(): number {
    if (this.themeMode) {
      return new Date('2022-02-22').getTime();
    } else if (this.accountInfo?.time?.seconds) {
      return this.accountInfo?.time?.seconds * 1000;
    } else if (this.accountInfo?.time?._seconds) {
      return this.accountInfo?.time?._seconds * 1000;
    }
    return -1;
  }

  /**
   * Get account date.
   * @returns account date
   */
  getDate(): string {
    let result = null;
    if (this.preview && this.themeMode) {
      result = this.getTranslate('VISITOR.theme.date');
    } else if (this.preview && this.language?.code && this.previewData?.info?.[this.language.code]?.date) {
       result = this.previewData.info[this.language.code].date;
    } else {
      if (this.pageInfo) {
        if (this.language?.code && this.pageInfo?.[this.language.code]) {
          result = this.pageInfo[this.language.code]?.date;
        }
      }
      if (this.functionService.isUndefined(result) || this.themeMode) {
        if (this.accountInfo?.time) {
          // console.log(this.language);
          if (this.accountInfo.time.seconds) {
            result = this.dateTimeService.format(this.accountInfo.time.seconds * 1000, 'do MMM yyyy, EEEE', this.language?.code, this.accountInfo?.timezone?.name);
          } else if (this.accountInfo.time._seconds) {
            result = this.dateTimeService.format(this.accountInfo.time._seconds * 1000, 'do MMM yyyy, EEEE', this.language?.code, this.accountInfo?.timezone?.name);
          }
        }
      }
    }
    if (result) {
      result = this.getVariable(result);
    }
    return result;
  }

  /**
   * Get start time.
   * @returns account start time
   */
  getStartTime(): string {
    let result = null;
    if (this.preview && this.themeMode) {
      result = this.getTranslate('VISITOR.theme.startTime');
    } else if (this.preview && this.language?.code && this.previewData?.info?.[this.language.code]?.startTime) {
       result = this.previewData.info[this.language.code].startTime;
    } else {
      if (this.pageInfo) {
        if (this.language?.code && this.pageInfo?.[this.language.code]) {
          result = this.pageInfo[this.language.code]?.startTime;
        }
      }
      if (this.functionService.isUndefined(result) || this.themeMode) {
        if (this.accountInfo?.time) {
          if (this.accountInfo.time?.seconds) {
            result = this.dateTimeService.format(this.accountInfo.time.seconds * 1000, 'p', this.language?.code, this.accountInfo?.timezone?.name);
          } else if (this.accountInfo.time?._seconds) {
            result = this.dateTimeService.format(this.accountInfo.time._seconds * 1000, 'p', this.language?.code, this.accountInfo?.timezone?.name);
          }
        }
      }
    }
    if (result) {
      result = this.getVariable(result);
    }
    return result;
  }

  /**
   * Get end time.
   * @returns account end time
   */
  getEndTime(): string {
    let result = '';
    if (this.preview && this.themeMode) {
      result = this.getTranslate('VISITOR.theme.endTime');
    } else if (this.preview && this.language?.code && this.previewData?.info?.[this.language.code]?.endTime) {
       result = this.previewData.info[this.language.code].endTime;
    } else {
      if (this.pageInfo) {
        if (this.language?.code && this.pageInfo?.[this.language.code]?.endTime) {
          result = this.pageInfo[this.language.code].endTime;
        }
      }
    }
    return result;
  }

  /**
   * Get cocktail time.
   * @returns cocktail time.
   */
  getCocktail(): string {
    let result = '';
    if (this.preview && this.themeMode) {
      result = this.getTranslate('VISITOR.theme.cocktailTime');
    } else if (this.preview && this.language?.code && this.previewData?.info?.[this.language.code]?.cocktailTime) {
      result = this.previewData.info[this.language.code].cocktailTime;
    } else {
      if (this.pageInfo) {
        if (this.language?.code && this.pageInfo?.[this.language.code]?.cocktailTime) {
          result = this.pageInfo[this.language.code].cocktailTime;
        }
      }
    }
    return result;
  }

  /**
   * Get timezone
   * @returns timezone.
   */
  getTimezone(): string {
    let result: string = null;
    if (this.preview && this.themeMode) {
      result = this.getTranslate('VISITOR.theme.timezone');
    } else if (this.preview && this.language?.code && this.previewData?.info?.[this.language.code]?.timezone) {
       result = this.previewData.info[this.language.code].timezone;
    } else {
      if (this.pageInfo) {
        if (this.language?.code && this.pageInfo?.[this.language.code]) {
          result = this.pageInfo[this.language.code]?.timezone;
        }
      }
      if (this.functionService.isUndefined(result) || this.themeMode) {
        if (this.accountInfo?.timezone?.utc) {
          result = this.accountInfo.timezone.utc;
        }
      }
    }
    if (result) {
      result = this.getVariable(result);
    }
    return result;
  }

  /**
   * Get venue name.
   * @returns venue name.
   */
  getVenue(): string {
    let result: string = null;
    if (this.preview && this.themeMode) {
      if (this.localityService.getAccountCountry()?.code === 'MY') {
        result = this.getTranslate('VISITOR.theme.venue.MY.venue');
      } else if (this.localityService.getAccountCountry()?.code === 'HK') {
        result = this.getTranslate('VISITOR.theme.venue.HK.venue');
      } else if (this.localityService.getAccountCountry()?.code === 'TW') {
        result = this.getTranslate('VISITOR.theme.venue.TW.venue');
      } else {
        result = this.getTranslate('VISITOR.theme.venue.SG.venue');
      }
    } else if (this.preview && this.language?.code && this.previewData?.info?.[this.language.code]?.venue) {
      result = this.previewData.info[this.language.code].venue;
    } else {
      if (this.pageInfo) {
        if (this.language?.code && this.pageInfo?.[this.language.code]) {
          result = this.pageInfo[this.language.code]?.venue;
        }
      }
      if (this.functionService.isUndefined(result) || this.themeMode) {
        if (this.pageLocation?.name) {
          result = this.pageLocation.name;
        }
      }
    }
    return result;
  }

  /**
   * Get venue address
   * @returns venue address
   */
  getAddress(): string {
    let result: string = null;
    if (this.preview && this.themeMode) {
      if (this.localityService.getAccountCountry()?.code === 'MY') {
        result = this.getTranslate('VISITOR.theme.venue.MY.address');
      } else if (this.localityService.getAccountCountry()?.code === 'HK') {
        result = this.getTranslate('VISITOR.theme.venue.HK.address');
      } else if (this.localityService.getAccountCountry()?.code === 'TW') {
        result = this.getTranslate('VISITOR.theme.venue.TW.address');
      } else {
        result = this.getTranslate('VISITOR.theme.venue.SG.address');
      }
    } else if (this.preview && this.language?.code && this.previewData?.info?.[this.language.code]?.address) {
      result = this.previewData.info[this.language.code].address;
    } else {
      if (this.pageInfo) {
        if (this.language?.code && this.pageInfo?.[this.language.code]) {
          result = this.pageInfo[this.language.code]?.address;
        }
      }
      if (this.functionService.isUndefined(result) || this.themeMode) {
        if (this.pageLocation?.address) {
          result = this.pageLocation.address;
        }
      }
    }
    return result;
  }

  /**
   * Get hall
   * @returns hall name
   */
  getHall(): string {
    let result = '';
    if (this.preview && this.themeMode) {
      if (this.localityService.getAccountCountry()?.code === 'MY') {
        result = this.getTranslate('VISITOR.theme.venue.MY.hall');
      } else if (this.localityService.getAccountCountry()?.code === 'HK') {
        result = this.getTranslate('VISITOR.theme.venue.HK.hall');
      } else if (this.localityService.getAccountCountry()?.code === 'TW') {
        result = this.getTranslate('VISITOR.theme.venue.TW.hall');
      } else {
        result = this.getTranslate('VISITOR.theme.venue.SG.hall');
      }
    } else if (this.preview && this.language?.code && this.previewData?.info?.[this.language.code]?.hall) {
      result = this.previewData.info[this.language.code].hall;
    } else {
      if (this.pageInfo) {
        if (this.language?.code && this.pageInfo?.[this.language.code]?.hall) {
          result = this.pageInfo[this.language.code].hall;
        }
      }
    }
    return result;
  }

  /**
   * Get remark
   * @returns remark
   */
  getRemark(): string {
    let result = '';
    if (this.preview && this.language?.code &&  this.previewData?.info?.[this.language.code]?.remark) {
      result = this.previewData.info[this.language.code].remark;
    } else {
      if (this.pageInfo) {
        if (this.language?.code && this.pageInfo?.[this.language.code]?.remark) {
          result = this.pageInfo[this.language.code].remark;
        }
      }
    }
    return this.getLinkifyHtml(result);
  }

  getFloorplan(): string {
    let result = null;
    if (this.preview && this.language?.code && this.previewData?.info?.[this.language.code]?.floorplan) {
      result = this.previewData.info[this.language.code].floorplan;
    } else {
      if (this.pageInfo) {
        if (this.language?.code && this.pageInfo?.[this.language.code]?.floorplan) {
          result = this.pageInfo[this.language.code].floorplan;
        }
      }
    }
    if (this.functionService.isUndefined(result) || this.themeMode || result === 'VISITOR.setting.attachment.floorplan.title') {
      result = this.getTranslate('VISITOR.setting.attachment.floorplan.title');
    }

    if (result) {
      result = this.getVariable(result);
    }
    return result;
  }

  getMenu(): string {
    let result = null;
    if (this.preview && this.language?.code && this.previewData?.info?.[this.language.code]?.menu) {
      result = this.previewData.info[this.language.code].menu;
    } else {
      if (this.pageInfo) {
        if (this.language?.code && this.pageInfo?.[this.language.code]?.menu) {
          result = this.pageInfo[this.language.code].menu;
        }
      }
    }
    if (this.functionService.isUndefined(result) || this.themeMode || result === 'VISITOR.setting.attachment.menu.title') {
      result = this.getTranslate('VISITOR.setting.attachment.menu.title');
    }

    if (result) {
      result = this.getVariable(result);
    }
    return result;
  }

  getAttachment(): string {
    let result = null;
    if (this.preview && this.language?.code && this.previewData?.info?.[this.language.code]?.attachment) {
      result = this.previewData.info[this.language.code].attachment;
    } else {
      if (this.pageInfo) {
        if (this.language?.code && this.pageInfo?.[this.language.code]?.attachment) {
          result = this.pageInfo[this.language.code].attachment;
        }
      }
    }
    if (this.functionService.isUndefined(result) || this.themeMode || result === 'VISITOR.setting.attachment.title') {
      result = this.getTranslate('VISITOR.setting.attachment.title');
    }

    if (result) {
      result = this.getVariable(result);
    }
    return result;
  }

  /**
   * Get itinerary
   * @returns itinerary
   */
  getItinerary(): string {
    let result = '';
    if (this.preview && this.language?.code &&  this.previewData?.info?.[this.language.code]?.itinerary) {
      result = this.previewData.info[this.language.code].itinerary;
    } else {
      if (this.pageInfo) {
        if (this.language?.code && this.pageInfo?.[this.language.code]?.itinerary) {
          result = this.pageInfo[this.language.code].itinerary;
        }
      }
    }
    if (result) {
      result = this.getVariable(result);
    }
    return this.getLinkifyHtml(result);
  }

  getGreeting(): string {
    let result = null;
    if (this.preview && this.language?.code && this.previewData?.info?.[this.language.code] &&
    !this.functionService.isUndefined(this.previewData.info[this.language.code].greeting)) {
      result = this.previewData.info[this.language.code].greeting;
    } else if (this.language?.code && this.pageInfo?.[this.language.code] &&
    !this.functionService.isUndefined(this.pageInfo[this.language.code].greeting)) {
      result = this.pageInfo[this.language.code]?.greeting;
    }
    if (this.functionService.isUndefined(result) || this.themeMode) {
      result = this.getTranslate('VISITOR.variable.greeting');
    }
    if (result) {
      result = this.getVariable(result);
    }
    return result;
  }

  /**
   * Get visitor page msg
   * @returns visitor page msg
   */
  getMsg(): string {
    let result = null;
    if (this.preview && this.language?.code && this.previewData?.info?.[this.language.code]) {
      result = this.previewData.info[this.language.code].msg;
    } else if (this.language?.code && this.pageInfo?.[this.language.code]) {
      result = this.pageInfo[this.language.code]?.msg;
    }

    if (this.functionService.isUndefined(result) || this.themeMode) {
      result = this.getTranslate('VISITOR.msg');
    }
    if (result) {
      result = this.getVariable(result);
    }
    return this.getLinkifyHtml(result);
  }

  getPax(): string {
    let result = null;
    if (this.preview && this.language?.code && this.previewData?.info?.[this.language.code]) {
      result = this.previewData.info[this.language.code].pax;
    } else if (this.language?.code && this.pageInfo?.[this.language.code]) {
      result = this.pageInfo[this.language.code]?.pax;
    }

    if ((!result && (result === null || this.pageSetting.pax)) || this.themeMode) {
      result = this.getTranslate('VISITOR.variable.pax');
    }
    if (result) {
      result = this.getVariable(result);
    }
    return result;
  }

  getShowSeating(): boolean {
    if (this.checkinStatus && this.visitorMenuType === 'status') {
      return true;
    } else if (this.pageSetting.seatingBeforeCheckin) {
      return true;
    }
    // else if (this.pageSetting.checkinSeating) {
    //   if (this.group) {
    //     if (this.group?.status?.checkin) {
    //       return true;
    //     }
    //   } else if (this.guest) {
    //     if (this.guest?.status?.checkin) {
    //       return true;
    //     }
    //   }
    // }
    return false;
  }

  getSeating(): string {
    let result = null;
    if (this.preview && this.language?.code && this.previewData?.info?.[this.language.code]) {
      result = this.previewData.info[this.language.code].seating;
    } else if (this.language?.code && this.pageInfo?.[this.language.code]) {
      result = this.pageInfo[this.language.code]?.seating;
    }

    if ((!result && (result === null || this.pageSetting.seating)) || this.themeMode) {
      result = this.getTranslate('VISITOR.variable.seating');
    }
    if (result) {
      result = this.getVariable(result);
    }
    return result;
  }

  getSeatingTypeName(): string {
    let seatingType = this.seatingSettingService.getSeatingType();
    if (this.seatingType?.value) {
      if (this.seatingType.value === 'others' && !this.seatingType?.custom && this.seatingTypeOther) {
        this.seatingTypeName = this.seatingTypeOther;
        return this.seatingTypeOther;
      } else {
        seatingType = this.seatingType;
      }
    }

    if (seatingType?.value && !seatingType?.custom && this.translation?.LIST?.seating_type?.[seatingType.value]) {
      this.seatingTypeName = this.translation.LIST.seating_type[seatingType.value];
      return this.translation.LIST.seating_type[seatingType.value];
    } else {
      this.seatingTypeName = this.seatingSettingService.getSeatingTypeName(this.language?.code, seatingType);
      return this.seatingSettingService.getSeatingTypeName(this.language?.code, seatingType);
    }
  }

  getSession(): string {
    let result = null;
    if (this.preview && this.language?.code && this.previewData?.info?.[this.language.code]) {
      result = this.previewData.info[this.language.code].session;
    } else if (this.language?.code && this.pageInfo?.[this.language.code]) {
      result = this.pageInfo[this.language.code]?.session;
    }

    if ((!result && (result === null || this.pageSetting.session)) || this.themeMode) {
      result = this.getTranslate('VISITOR.variable.session');
    }
    if (result) {
      result = this.getVariable(result);
    }
    return result;
  }

  /**
   * Get theme
   * @returns theme
   */
  getTheme(): Theme {
    let themeId = '1.1';
    if (this.pageTheme) { themeId = this.pageTheme; }
    if (this.preview && this.previewData?.themeId) {
      themeId = this.previewData.themeId;
    }
    if (themeId === '1' || themeId === '2' || themeId === '3') {
      themeId = themeId + '.1';
    }

    const index = ThemeList.findIndex((x: Theme) => {
      return x.themeId === themeId;
    })

    return index !== -1 ? ThemeList[index] : ThemeList[0];
  }

  getThemeId() {
    const theme: Theme = this.getTheme();
    return theme?.themeId ? theme.themeId : '1.1';
  }

  getThemeBackground(): string {
    if (this.pageBackground) {
      return this.pageBackground;
    }
    const backgroundId = this.getTheme()?.backgroundId;
    return backgroundId ? backgroundId : '1';
  }

  /**
   * Get guest url
   * @returns guest url
   */
  getGuestUrl(): string {
    let url = '';
    if (this.accountId && this.guestId) {
      url = DynamicUrl.long.visitor4 + this.accountId + '/' + this.guestId;
    } else if (this.accountId && this.guest && this.guest?.guestId) {
      url = DynamicUrl.long.visitor4 + this.accountId + '/' + this.guest.guestId;
    } else if (this.guestId) {
      url = this.guestId;
    } else if (this.guest && this.guest?.guestId) {
      url = this.guest.guestId;
    } else if (this.accountId && this.groupId) {
      url = DynamicUrl.long.visitor4 + this.accountId + '/0/' + this.groupId;
    } else if (this.accountId && this.group?.groupId) {
      url = DynamicUrl.long.visitor4 + this.accountId + '/0/' + this.group.groupId;
    } else if (this.groupId) {
      url = this.groupId;
    } else if (this.group?.groupId) {
      url = this.group.groupId;
    }
    // this.config = this.qrcodeService.generateQRcodeConfig(url, 230, 230, 10, true);
    return url;
  }

  getLinkifyHtml(txt: string) {
    return linkifyHtml(txt, { target: { url: '_blank' }});
  }

  async generateQrcode() {
    const data = this.getGuestUrl();
    if (data) {
      // this.config = this.qrcodeService.generateQRcodeConfig(data, 230, 230, 10, true);
      // const qrcode: QRCodeStyling = await this.qrcodeService.generateQRcode(data, 230, 230, 10, 'svg', true) as QRCodeStyling;
      // if (qrcode) {
      //   let flag = false;
      //   let elementCounter = 0;
      //   try {
      //     do {
      //       elementCounter++;
      //       if (this.canvas?.nativeElement) {
      //         qrcode.append(this.canvas.nativeElement);
      //         flag = true;
      //       } else {
      //         await this.functionService.delay(200);
      //       }
      //     } while (!flag && elementCounter < 25);
      //   } catch(err: any) {
      //     await this.functionService.delay(200);
      //     this.generateQrcode();
      //   }
      // } else {
      //   await this.functionService.delay(200);
      //   this.generateQrcode();
      // }
    } else {
      await this.functionService.delay(200);
      this.generateQrcode();
    }
  }

  getLogo() {
    let logo = '';
    if (this.logo) {
      logo = this.logo;
      this.customLogo = true;
    } else if ((this.preview || this.themeMode) && this.visitorLogoService.logo) {
      logo = this.visitorLogoService.logo;
      this.customLogo = true;
    }
    if (this.functionService.isEmpty(logo) || this.functionService.isUndefined(logo)) {
      logo = './assets/wedding/img/logo/thebigday-logo-round-primary.svg';
      this.customLogo = false;
    }
    return logo;
  }

  /**
   * Get timezone
   * @returns timezone.
   */
  getAccountTime(): string {
    let result = '';
    if (this.accountInfo?.time) {
      if (this.accountInfo.time.seconds) {
        result = this.dateTimeService.format(this.accountInfo.time.seconds * 1000, 'PPpp', this.language?.code, this.accountInfo?.timezone?.name);
      } else if (this.accountInfo.time._seconds) {
        result = this.dateTimeService.format(this.accountInfo.time._seconds * 1000, 'PPpp', this.language?.code, this.accountInfo?.timezone?.name);
      }
    }
    if (result) {
      result = this.getVariable(result);
    }
    return result;
  }

  getMonth(timestamp: number, format?: string) {
    if (format) {
      return this.dateTimeService.format(timestamp, format, this.language?.code, this.accountInfo?.timezone?.name);
    }

    let type = 'LLL';
    if (this.language?.code === 'zh') {
      type = 'M';
    } else if (this.language?.code === 'zh-TW') {
      type = 'M';
    } else if (this.language?.code === 'ms') {
    }
    return this.dateTimeService.format(timestamp, type, this.language?.code, this.accountInfo?.timezone?.name);
  }

  formatDate(timestamp: number, format: string) {
    return this.dateTimeService.format(timestamp, format, this.language?.code, this.accountInfo?.timezone?.name);
  }

  getVariable(msg: string) {
    if (msg) {
      if (msg.indexOf('[GUEST_NAME]') !== -1) {
        const name = this.group?.groupName ? this.group.groupName : this.guest?.name ? this.guest.name : '';
        msg = this.functionService.replaceAll(msg, '[GUEST_NAME]', name);
        // msg = msg.replace(/\[GUEST_NAME\]/g, name);
      }
      if (msg.indexOf('[GUEST_ALTERNATE_NAME]') !== -1) {
        const name = this.guest?.nickname ? this.guest.nickname : '';
        msg = this.functionService.replaceAll(msg, '[GUEST_ALTERNATE_NAME]', name);
      }
      if (msg.indexOf('{{ groupName }}') !== -1) {
        const name = this.group?.groupName ? this.group.groupName : this.guest?.group?.groupName ? this.guest.group.groupName : '';
        msg = this.functionService.replaceAll(msg, '{{ groupName }}', name);
      }
      if (msg.indexOf('[GROUP_NAME]') !== -1) {
        const name = this.group?.groupName ? this.group.groupName : this.guest?.group?.groupName ? this.guest.group.groupName : '';
        msg = this.functionService.replaceAll(msg, '[GROUP_NAME]', name);
      }
      if (msg.indexOf('[WEDDING_TITLE]') !== -1 && this.accountInfo?.title?.value) {
        msg = this.functionService.replaceAll(msg, '[WEDDING_TITLE]', this.accountInfo.title.value);
      }
      if (msg.indexOf('[WEDDING_DATE]') !== -1) {
        if (this.accountInfo?.time?.seconds) {
          msg = this.functionService.replaceAll(msg, '[WEDDING_DATE]', this.dateTimeService.format(this.accountInfo.time.seconds * 1000, 'do MMM yyyy, EEEE', this.language?.code, this.accountInfo?.timezone?.name));
        } else if (this.accountInfo?.time?._seconds) {
          msg = this.functionService.replaceAll(msg, '[WEDDING_DATE]', this.dateTimeService.format(this.accountInfo.time._seconds * 1000, 'do MMM yyyy, EEEE', this.language?.code, this.accountInfo?.timezone?.name));
        }
      }
      if (msg.indexOf('[WEDDING_START_TIME]') !== -1) {
        if (this.accountInfo?.time?.seconds) {
          msg = this.functionService.replaceAll(msg, '[WEDDING_START_TIME]', this.dateTimeService.format(this.accountInfo.time.seconds * 1000, 'p', this.language?.code, this.accountInfo?.timezone?.name));
        } else if (this.accountInfo?.time?._seconds) {
          msg = this.functionService.replaceAll(msg, '[WEDDING_START_TIME]', this.dateTimeService.format(this.accountInfo.time._seconds * 1000, 'p', this.language?.code, this.accountInfo?.timezone?.name));
        }
      }
      if (msg.indexOf('[WEDDING_TIMEZONE]') !== -1 && this.accountInfo?.timezone?.utc) {
        msg = this.functionService.replaceAll(msg, '[WEDDING_TIMEZONE]', this.accountInfo.timezone.utc);
      }

      if (msg.indexOf('[GUEST_SEATING]') !== -1) {
        let seating = this.guest?.seating ? this.guest.seating : '';
        if (this.group?.seating?.length) {
          const seatingList = this.group.seating;
          seating = [ ...seatingList ].filter((x: string, i: number) => {
            return seatingList.indexOf(x) === i;
          }).join(', ');
          seating = this.group.seating.join(', ');
        } else if (this.group?.guestList?.length) {
          const seatingList = this.group?.guestList?.filter((guest: Guest) => {
            return guest?.seating ? true : false;
          })?.map((guest: Guest) => {
            return guest.seating;
          });
          seating = [ ...seatingList ].filter((x: string, i: number) => {
            return seatingList.indexOf(x) === i;
          }).join(', ');
        }
        msg = this.functionService.replaceAll(msg, '[GUEST_SEATING]', seating ? seating : '-');
      }
      if (msg.indexOf('[SEATING_TYPE]') !== -1) {
        msg = this.functionService.replaceAll(msg, '[SEATING_TYPE]', this.getSeatingTypeName());
      }
      if (this.pageSetting?.pax && msg.indexOf('[NUMBER_OF_GUEST]') !== -1) {
        const pax = this.group?.guestList?.length ? this.group.guestList.length : this.guest?.pax ? this.guest.pax : '1';
        msg = this.functionService.replaceAll(msg, '[NUMBER_OF_GUEST]', pax);
      }
      if (this.pageSetting?.session && msg.indexOf('[GUEST_SESSION]') !== -1) {
        let session = '';
        if (this.group) {
          const sessionList: string[] = [];
          if (this.group?.guestList?.length && !this.group?.session?.length) {
            this.group.session = [ ...new Set( this.group?.guestList.filter((guest: Guest) => {
              if (guest?.session?.[0]?.value && guest.session[0].value !== 'none') {
                  return true;
              }
              return false;
            }).map((guest: Guest) => {
                return guest.session?.[0];
            }) ) ];
          }
          if (this.group?.session?.length) {
            this.group?.session?.forEach((x: SettingField) => {
              if (x.value && x.value !== 'none') {
                if (sessionList.indexOf(x.value) === -1) {
                  sessionList.push(x.value);
                }
              }
            });
          }
          if (sessionList?.length) {
            session = sessionList.join(', ');
          }
        } else {
          session = this.guest?.session?.[0]?.value && this.guest.session[0].value !== 'none' ? this.guest.session[0].value : '';
        }
        if (!session) {
          session = '-';
        }
        msg = this.functionService.replaceAll(msg, '[GUEST_SESSION]', session);
      }
    }
    return msg;
  }

  getBtnOutline() {
    const themeBackground = this.getThemeBackground();
    const themeId = this.getThemeId();
    if (themeBackground === '12' || themeBackground === '13') {
      return 'clear';
    }
    if (themeId === '18.2.1' || themeId === '18.2.2' || themeId === '18.2.3' || themeId === '19.2.1' || themeId === '19.2.2' || themeId === '19.2.3' || themeId === '20.2.1' || themeId === '20.2.2' || themeId === '20.2.3' || themeId === '21.2.1' || themeId === '21.2.2' || themeId === '21.2.3' || themeId === '22.2.1' || themeId === '22.2.2' || themeId === '22.2.3') {
      return 'solid';
    }
    return 'outline';
  }

  getBtnColor(type?: string) {
    const themeBackground = this.getThemeBackground();
    if (themeBackground === '12' || themeBackground === '13' || themeBackground === '14') {
      return '';
    } else if (themeBackground === '7') {
      if (type === 'how' || type === 'checkin') {
        return 'white';
      }
    } else if (themeBackground === '18' || themeBackground === '19') {
      return 'white';
    }

    return 'dark';
  }

  replaceAll(text: string, search: string, replace: string) {
    return this.functionService.replaceAll(text, search, replace);
  }

  /**
   * Present language picker
   * @param value Velue
   */
  async presentLanguagePicker(value?: string) {
    if (this.pageLangList?.length > 1 && !this.themeMode) {
      const options = [];
      this.pageLangList?.forEach((language: Language) => {
        options.push({
          text: language.name,
          value: language.code
        });
      });
      const selectedIndex = options?.findIndex((x: any) => x.value === value);
      const picker = await this.pickerController.create({
        columns: [{
          name: 'language',
          options,
          selectedIndex
        }],
        buttons: [{
          text: this.getTranslate('BTN.cancel'),
          role: 'cancel'
        }, {
          text: this.getTranslate('BTN.confirm'),
          handler: (result: any) => {
            if (result?.language?.value !== this.language.code) {
              this.language.code = result.language.value;
              this.language.name = result.language.text;
              this.getTranslation();
            }
          }
        }]
      });
      picker.present();
    }
  }

  /**
   * Open google navigation
   */
  openGoogleNav() {
    this.analyticsService.openGps(this.accountId, 'googleMap', this.pageLocation?.name);
    this.gpsService.openGoogleNav(this.pageLocation);
  }

  /**
   * Open waze navigation
   */
  openWazeNav() {
    this.analyticsService.openGps(this.accountId, 'waze', this.pageLocation?.name);
    this.gpsService.openWazeNav(this.pageLocation);
  }

  /**
   * Open uber navigation
   */
  openUberNav() {
    this.analyticsService.openGps(this.accountId, 'uber', this.pageLocation?.name);
    this.gpsService.openUberNav(this.pageLocation);
  }

  openGrab() {
    this.analyticsService.openGps(this.accountId, 'grab', this.pageLocation?.name);
    this.gpsService.openGrab(this.pageLocation);
  }

  openAppleNav() {
    this.analyticsService.openGps(this.accountId, 'appleMap', this.pageLocation?.name);
    this.gpsService.openAppleNav(this.pageLocation);
  }

  async openGps(url: string) {
    url = encodeURI(url);
    if (this.platform.is('ios') && this.platform.is('hybrid')) {
      window.open(url, '_system');
    } else {
      this.openUrl(url);
    }
  }

  async openCalendar(type: string) {
    if (!this.themeMode) {
      this.analyticsService.addCalendar(this.accountId);
      if (this.platform.is('hybrid')) {
        const permission = await this.calendar.hasWritePermission();
        if (permission) {
          this.calendar.createEventWithOptions(
            this.getTitle(),
            this.getVenue(),
            '',
            new Date(this.getAccountTime()),
            new Date(this.getAccountTime()),
          );
          this.popupService.presentToast(this.getTranslate('MSG.action_done'), 'success');
        } else {
          await this.calendar.requestWritePermission();
        }
      } else {
        const singleEventArgs = {
          title       : this.getTitle(),
          start       : this.getAccountTime(),
          end         : this.getAccountTime(),
          location    : this.getVenue(),
          description : this.getRemark(),
          isAllDay    : false
        };
        const calendar = new Add2Calendar(singleEventArgs);
        let url = '';
        if (type === 'google') {
          url = calendar.getGoogleUrl();
        } else if (type === 'ical') {
          url = calendar.getICalUrl();
        } else if (type === 'outlook') {
          url = calendar.getOutlookUrl();
        } else if (type === 'yahoo') {
          url = calendar.getYahooUrl();
        }
        if (url) {
          // window.open(url);
          this.openUrl(url);
        }
      }
    }
  }

  openHowPage() {
    if (!this.themeMode) {
      this.analyticsService.openGuestPageHowBtn(this.accountId);
      if (this.language?.code === 'zh' || this.language?.code === 'zh-TW') {
        this.openUrl(QrHowVideoUrl['zh-TW']);
      } else {
        this.openUrl(QrHowVideoUrl.en);
      }
    }
  }

  /**
   * Go website
   */
  goWebsite() {
    if (!this.themeMode) {
      this.analyticsService.guestPageOpenWebsite(this.accountId);
      this.linkService.goWebsite();
    }
  }

  refresh() {
    this.showRefreshBtn = false;
    window.location.reload();
  }

  async openUrl(url: string) {
    this.linkService.openUrl(url);
  }

  getContainerWidth() {
    return this.attachmentDiv?.nativeElement?.offsetWidth;
  }

  getOuterContainerWidth() {
    // console.log(this.attachmentOuterDiv?.nativeElement?.offsetWidth);
    // return this.attachmentOuterDiv?.nativeElement?.offsetWidth;
  }

  getDefaultConfig(): Options {
    return {
      width: 215,
      height: 215,
      type: 'canvas',
      margin: 5,
      qrOptions: {
        typeNumber: 0,
        mode: 'Byte',
        errorCorrectionLevel: 'H'
      },
      imageOptions: {
        hideBackgroundDots: false,
        imageSize: 0.4,
        margin: 0,
        crossOrigin: 'anonymous',
      },
      dotsOptions: {
        color: '#000000',
        type: 'dots'
      },
      backgroundOptions: {
      },
      cornersSquareOptions: {
        color: '#000000',
        type: 'extra-rounded',
      },
      cornersDotOptions: {
        color: '#000000',
        type: 'dot',
  
      }
    };
  }

  getLogoSize() {
    if (this.logoSize) {
      return this.logoSize;
    } else if (this.visitorLogoService?.logoSize) {
      return this.visitorLogoService.logoSize;
    } else {
      return 0.3;
    }
  }

  getLogoWidth() {
    if (!this.config) {
      this.config = this.getDefaultConfig();
    }
    return this.config.width * this.getLogoSize();
  }

  // setAttachmentSwiper(swiper: Swiper) {
  //   this.attachmentSwiper = swiper;
  //   if (this.attachmentSwiper) {
  //     setTimeout(() => {
  //       this.attachmentSwiper?.autoplay?.start();
  //     }, 12000);
  //   }
  // }

  // setBtnSwiper(swiper: Swiper) {
  //   this.btnSwiper = swiper;
  //   if (this.btnSwiper) {
  //     setTimeout(() => {
  //       this.btnSwiper?.autoplay?.start();
  //     }, 1000);
  //   }
  // }

  getSlidePerView(type: string) {
    const width = this.getContainerWidth();
    let slidePerView = 1;

    if (type === 'attachment') {
      const maxWidthPerSlide = 160;
      let totalSlides = 0;
      if (this.pageSetting?.floorplan?.url) {
        totalSlides += 1;
      }
      if (this.pageSetting?.menu?.url) {
        totalSlides += 1;
      }
      if (this.pageSetting?.attachment?.[0]?.url) {
        totalSlides += 1;
      }

      if (width >= (maxWidthPerSlide * 3)) {
        slidePerView = 3;
      } else if (width >= (maxWidthPerSlide * 2.5)) {
        slidePerView = 2.5;
      } else if (width >= (maxWidthPerSlide * 2)) {
        slidePerView = 2;
      } else if (width >= (maxWidthPerSlide * 1.5)) {
        slidePerView = 1.5;
      } else {
        if (totalSlides > 1) {
          slidePerView = 1.2;
        }
      }
      // if (this.pageSetting?.attachment?.length && slidePerView > this.pageSetting.attachment.length) {
      //   slidePerView = this.pageSetting.attachment.length;
      // }
      if (slidePerView > 1) {
        if (slidePerView > totalSlides) {
          slidePerView = totalSlides;
        }
      }
      // if (this.attachmentSlidePerView !== slidePerView) {
      //   this.attachmentSlidePerView = slidePerView;
      //   this.attachmentSwiper?.update();
      //   this.attachmentSwiper?.updateAutoHeight();
      // }
    } else if (type === 'btn') {
      const maxWidthPerSlide = 160;
      if (width > (maxWidthPerSlide * 2)) {
        slidePerView = 2;
      } else if (width > (maxWidthPerSlide * 1.5)) {
        slidePerView = 1.5;
      } else {
        if (this.pageSetting?.webCheckin && this.pageSetting?.qrcode && this.pageSetting?.btn) {
          slidePerView = 1.25;
        }
      }
      if (slidePerView > 1 && (!this.pageSetting?.webCheckin || !this.pageSetting?.qrcode || !this.pageSetting?.btn)) {
        slidePerView = 1;
      }
    }
    
    return slidePerView;
  }

  openFile(attachment: VisitorAttachment) {
    this.analyticsService.openAttachment(this.accountId, attachment?.type);
    this.fileService.openFile(attachment.url, attachment.mimeType);
  }

  menuChanged(event: any) {
    this.checkGif();
  }

  checkGif() {
    this.stopGif = false;
    if (this.visitorMenuType === 'status') {
      setTimeout(() => {
        this.stopGif = true;
      }, 2200);
    }
  }

  calculateWidth(): number {
    let size = 0;
    if (this.checkinDiv?.nativeElement?.offsetWidth) {
      size = this.checkinDiv?.nativeElement?.offsetWidth; 
    }
    if (size > 300) {
      size = 300;
    }
    return size;
  }

  calculateMaxWidth() {
    const width = 70;
    let result = 0;
    if (this.checkGps('googleMap')) {
      result += width;
    }
    if (this.checkGps('waze')) {
      result += width;
    }
    if (this.checkGps('uber')) {
      result += width;
    }
    if (this.checkGps('grab')) {
      result += width;
    }
    if (this.checkGps('appleMap')) {
      result += width;
    }
    return result;
  }

  setupGpsCheck() {
    this.appleMap = this.checkGps('appleMap');
    this.googleMap = this.checkGps('googleMap');
    this.grab = this.checkGps('grab');
    this.waze = this.checkGps('waze');
    this.uber = this.checkGps('uber');
  }

  checkGps(type: GpsType): boolean {
    if (type && ((this.pageLocation?.coordinate && this.pageLocation?.name) || this.themeMode)) {
      let country = '';
      if (this.pageLocation?.locality?.country) {
        country = this.pageLocation.locality.country;
      } else if (this.accountInfo?.location?.locality?.country) {
        country = this.accountInfo.location.locality.country
      }

      if (country) {
        country = country.toUpperCase();
        if (type === 'googleMap') {

          if (this.pageSetting?.googleMap && this.visitorSettingService.checkGoogleMapCountry(country)) {
            return true;
          }
        } else if (type === 'waze') {
          if (this.pageSetting?.waze && this.visitorSettingService.checkWazeCountry(country)) {
            return true;
          }
        } else if (type === 'uber') {
          if (this.pageSetting?.uber && this.visitorSettingService.checkUberCountry(country)) {
            return true;
          }
        } else if (type === 'grab') {
          if (this.visitorSettingService.checkGrabCountry(country)) {
            if (this.pageSetting?.grab) {
              return  true;
            } else if (this.functionService.isUndefined(this.pageSetting?.grab)) {
              return true;
            }
          }
        } else if (type === 'appleMap') {
          if (this.pageSetting?.apple) {
            return true;
          } else if (this.functionService.isUndefined(this.pageSetting?.apple)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  updateCheckinStatus(checkinStatus: boolean) {
    this.checkinStatus = checkinStatus;
    if (this.checkinStatus) {
      this.visitorMenuType = 'status';
      this.checkGif();
    } else {
      this.visitorMenuType = 'qrcode';
    }
    this.showSeating = this.getShowSeating();
  }
}

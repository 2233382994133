import {
  af, 
  ar, 
//   arDZ, 
//   arEG, 
//   arMA, 
//   arSA, 
//   arTN, 
  az, 
  be, 
//   beTarask, 
  bg, 
  bn, 
  bs, 
  ca, 
  cs, 
  cy, 
  da, 
  de, 
//   deAT, 
  el, 
//   enAU, 
//   enCA, 
  enGB, 
//   enIE, 
//   enIN, 
//   enNZ, 
  enUS, 
//   enZA, 
//   eo, 
  es, 
  et, 
  eu,
  faIR, 
  fi, 
  fr, 
//   frCA, 
//   frCH, 
  fy, 
  gd, 
  gl, 
  gu, 
  he, 
  hi, 
  hr, 
  ht, 
  hu, 
  hy, 
  id, 
  is, 
  it, 
//   itCH, 
  ja, 
//   jaHira, 
  ka, 
  kk, 
  km, 
//   kn, 
  ko, 
  lb, 
  lt, 
  lv, 
  mk, 
  mn, 
  ms, 
  mt, 
  nb, 
  nl, 
//   nlBE, 
  nn, 
  oc, 
  pl, 
  pt, 
//   ptBR, 
  ro, 
  ru, 
  sk, 
  sl, 
  sq, 
  sr, 
//   srLatn, 
  sv, 
  ta, 
  te, 
  th, 
  tr, 
//   ug, 
  uk, 
  uz, 
//   uzCyrl, 
  vi, 
  zhCN, 
  zhHK, 
  zhTW
} from 'date-fns/locale';
import { LocaleType } from '../interfaces/database';



export const LocaleList: LocaleType[] = [
    {
        dateFnsLocale: af,
        locale: 'af',
        summary: "Afrikaans",
        language: "Afrikaans"
    },
    {
        dateFnsLocale: ar,
        locale: 'ar',
        // summary: "Arabic (Modern Standard Arabic - Al-fusha)",
        summary: "Arabic",
        language: "Modern Standard Arabic"
    },
    // {
    //     dateFnsLocale: arDZ,
    //     locale: 'ar-DZ',
    //     summary: "Arabic (Algerian Arabic)",
    //     language: "Algerian Arabic"
    // },
    // {
    //     dateFnsLocale: arEG,
    //     locale: 'ar-EG',
    //     summary: "Arabic (Egypt)",
    //     language: "Arabic"
    //     },
    // {
    //     dateFnsLocale: arMA,
    //     locale: 'ar-MA',
    //     summary: "Arabic (Moroccan Arabic)",
    //     language: "Moroccan Arabic"
    // },
    // {
    //     dateFnsLocale: arSA,
    //     locale: 'ar-SA',
    //     summary: "Arabic (Saudi Arabic)",
    //     language: "Arabic"
    // },
    // {
    //     dateFnsLocale: arTN,
    //     locale: 'ar-TN',
    //     summary: "Arabic (Tunisian Arabic)",
    //     language: "Arabic"
    // },
    {
        dateFnsLocale: az,
        locale: 'az',
        summary: "Azerbaijani",
        language: "Azerbaijani"
    },
    {
        dateFnsLocale: be,
        locale: 'be',
        summary: "Belarusian",
        language: "Belarusian"
    },
    {
        dateFnsLocale: bg,
        locale: 'bg',
        summary: "Bulgarian",
        language: "Bulgarian"
    },
    {
        dateFnsLocale: bn,
        locale: 'bn',
        summary: "Bengali",
        language: "Bengali"
    },
    {
        dateFnsLocale: bs,
        locale: 'bs',
        summary: "Bosnian",
        language: "Bosnian"
    },
    {
        dateFnsLocale: ca,
        locale: 'ca',
        summary: "Catalan",
        language: "Catalan"
    },
    {
        dateFnsLocale: cs,
        locale: 'cs',
        summary: "Czech",
        language: "Czech"
    },
    {
        dateFnsLocale: cy,
        locale: 'cy',
        summary: "Welsh",
        language: "Welsh"
    },
    {
        dateFnsLocale: da,
        locale: 'da',
        summary: "Danish",
        language: "Danish"
    },
    {
        dateFnsLocale: de,
        locale: 'de',
        summary: "German",
        language: "German"
    },
    // {
    //     dateFnsLocale: deAT,
    //     locale: 'de-AT',
    //     summary: "German (Austria)",
    //     language: "German"
    // },
    {
        dateFnsLocale: el,
        locale: 'el',
        summary: "Greek",
        language: "Greek"
    },
    // {
    //     dateFnsLocale: enAU,
    //     locale: 'en-AU',
    //     summary: "English (Australia)",
    //     language: "English"
    // },
    // {
    //     dateFnsLocale: enCA,
    //     locale: 'en-CA',
    //     summary: "English (Canada)",
    //     language: "English"
    // },
    {
        dateFnsLocale: enGB,
        locale: 'en-GB',
        summary: "English (United Kingdom)",
        language: "English (United Kingdom)"
    },
    // {
    //     dateFnsLocale: enIE,
    //     locale: 'en-IE',
    //     summary: "English (Ireland)",
    //     language: "English"
    // },
    // {
    //     dateFnsLocale: enIN,
    //     locale: 'en-IN',
    //     summary: "English (India)",
    //     language: "English"
    // },
    // {
    //     dateFnsLocale: enNZ,
    //     locale: 'en-NZ',
    //     summary: "English (New Zealand)",
    //     language: "English"
    // },
    {
        dateFnsLocale: enUS,
        locale: 'en-US',
        summary: "English (United States)",
        language: "English (United States)"
    },
    // {
    //     dateFnsLocale: enZA,
    //     locale: 'en-ZA',
    //     summary: "English (South Africa)",
    //     language: "English"
    // },
    // {
    //     dateFnsLocale: eo,
    //     locale: 'en-EO',
    //     summary: "Esperanto",
    //     language: "Esperanto"
    // },
    {
        dateFnsLocale: es,
        locale: 'es',
        summary: "Spanish",
        language: "Spanish"
    },
    {
        dateFnsLocale: et,
        locale: 'et',
        summary: "Estonian",
        language: "Estonian"
    },
    {
        dateFnsLocale: eu,
        locale: 'eu',
        summary: "Basque",
        language: "Basque"
    },
    {
        dateFnsLocale: faIR,
        locale: 'fa-IR',
        summary: "Persian",
        language: "Persian"
    },
    {
        dateFnsLocale: fi,
        locale: 'fi',
        summary: "Finnish",
        language: "Finnish"
    },
    {
        dateFnsLocale: fr,
        locale: 'fr',
        summary: "French",
        language: "French"
    },
    // {
    //     dateFnsLocale: frCA,
    //     locale: 'fr-CA',
    //     summary: "French (Canada)",
    //     language: "French"
    // },
    // {
    //     dateFnsLocale: frCH,
    //     locale: 'fr-CH',
    //     summary: "French (Switzerland)",
    //     language: "French"
    // },
    {
        dateFnsLocale: fy,
        locale: 'fy',
        summary: "Western Frisian (Netherlands)",
        language: "West Frisian"
    },
    {
        dateFnsLocale: gd,
        locale: 'gd',
        summary: "Scottish Gaelic",
        language: "Scottish Gaelic"
    },
    {
        dateFnsLocale: gl,
        locale: 'gl',
        summary: "Galician",
        language: "Galician"
    },
    {
        dateFnsLocale: gu,
        locale: 'gu',
        summary: "Gujarati (India)",
        language: "Gujarati"
    },
    {
        dateFnsLocale: he,
        locale: 'he',
        summary: "Hebrew",
        language: "Hebrew"
    },
    {
        dateFnsLocale: hi,
        locale: 'hi',
        summary: "Hindi (India)",
        language: "Hindi"
    },
    {
        dateFnsLocale: hr,
        locale: 'hr',
        summary: "Croatian",
        language: "Croatian"
    },
    {
        dateFnsLocale: ht,
        locale: 'ht',
        summary: "Haitian Creole",
        language: "Haitian Creole"
    },
    {
        dateFnsLocale: hu,
        locale: 'hu',
        summary: "Hungarian",
        language: "Hungarian"
    },
    {
        dateFnsLocale: hy,
        locale: 'hy',
        summary: "Armenian",
        language: "Armenian"
    },
    {
        dateFnsLocale: id,
        locale: 'id',
        summary: "Indonesian",
        language: "Indonesian"
    },
    {
        dateFnsLocale: is,
        locale: 'is',
        summary: "Icelandic",
        language: "Icelandic"
    },
    {
        dateFnsLocale: it,
        locale: 'it',
        summary: "Italian",
        language: "Italian"
    },
    // {
    //     dateFnsLocale: itCH,
    //     locale: 'it-CH',
    //     summary: "Italian (Switzerland)",
    //     language: "Italian"
    // },
    {
        dateFnsLocale: ja,
        locale: 'ja',
        summary: "Japanese",
        language: "Japanese"
    },
    // {
    //     dateFnsLocale: jaHira,
    //     locale: 'jaHira',
    //     summary: "Japanese (Hiragana)",
    //     language: "Japanese (Hiragana)"
    // },
    {
        dateFnsLocale: ka,
        locale: 'ka',
        summary: "Georgian",
        language: "Georgian"
    },
    {
        dateFnsLocale: kk,
        locale: 'kk',
        summary: "Kazakh",
        language: "Kazakh"
    },
    {
        dateFnsLocale: km,
        locale: 'km',
        summary: "Khmer (Cambodian)",
        language: "Khmer"
    },
    // {
    //     dateFnsLocale: kn,
    //     locale: 'kn',
    //     summary: "Kannada (India)",
    //     language: "Kannada"
    // },
    {
        dateFnsLocale: ko,
        locale: 'ko',
        summary: "Korean",
        language: "Korean"
    },
    {
        dateFnsLocale: lb,
        locale: 'lb',
        summary: "Luxembourgish",
        language: "Luxembourgish"
    },
    {
        dateFnsLocale: lt,
        locale: 'lt',
        summary: "Lithuanian",
        language: "Lithuanian"
    },
    {
        dateFnsLocale: lv,
        locale: 'lv',
        summary: "Latvian (Latvia)",
        language: "Latvian"
    },
    {
        dateFnsLocale: mk,
        locale: 'mk',
        summary: "Macedonian",
        language: "Macedonian"
    },
    {
        dateFnsLocale: mn,
        locale: 'mn',
        summary: "Mongolian",
        language: "Mongolian"
    },
    {
        dateFnsLocale: ms,
        locale: 'ms',
        summary: "Malay",
        language: "Malay"
    },
    {
        dateFnsLocale: mt,
        locale: 'mt',
        summary: "Maltese",
        language: "Maltese"
    },
    {
        dateFnsLocale: nb,
        locale: 'nb',
        summary: "Norwegian Bokmål",
        language: "Norwegian Bokmål"
    },
    {
        dateFnsLocale: nl,
        locale: 'nl',
        summary: "Dutch",
        language: "Dutch"
    },
    // {
    //     dateFnsLocale: nlBE,
    //     locale: 'nl-BE',
    //     summary: "Dutch (Belgium)",
    //     language: "Dutch (Belgium)"
    // },
    {
        dateFnsLocale: nn,
        locale: 'nn',
        summary: "Norwegian Nynorsk",
        language: "Norwegian Nynorsk"
    },
    {
        dateFnsLocale: oc,
        locale: 'oc',
        summary: "Occitan",
        language: "Occitan"
    },
    {
        dateFnsLocale: pl,
        locale: 'pl',
        summary: "Polish",
        language: "Polish"
    },
    {
        dateFnsLocale: pt,
        locale: 'pt',
        summary: "Portuguese",
        language: "Portuguese"
    },
    // {
    //     dateFnsLocale: ptBR,
    //     locale: 'pt-BR',
    //     summary: "Portuguese (Brazil)",
    //     language: "Portuguese"
    // },
    {
        dateFnsLocale: ro,
        locale: 'ro',
        summary: "Romanian",
        language: "Romanian"
    },
    {
        dateFnsLocale: ru,
        locale: 'ru',
        summary: "Russian",
        language: "Russian"
    },
    {
        dateFnsLocale: sk,
        locale: 'sk',
        summary: "Slovak",
        language: "Slovak"
    },
    {
        dateFnsLocale: sl,
        locale: 'sl',
        summary: "Slovenian",
        language: "Slovenian"
    },
    {
        dateFnsLocale: sq,
        locale: 'sq',
        summary: "Albanian",
        language: "Shqip"
    },
    {
        dateFnsLocale: sr,
        locale: 'sr',
        summary: "Serbian cyrillic",
        language: "Serbian"
    },
    // {
    //     dateFnsLocale: srLatn,
    //     locale: 'sr-Latn',
    //     summary: "Serbian latin",
    //     language: "Serbian"
    // },
    {
        dateFnsLocale: sv,
        locale: 'sv',
        summary: "Swedish",
        language: "Swedish"
    },
    {
        dateFnsLocale: ta,
        locale: 'ta',
        summary: "Tamil (India)",
        language: "Tamil"
    },
    {
        dateFnsLocale: te,
        locale: 'te',
        summary: "Telugu",
        language: "Telugu"
    },
    {
        dateFnsLocale: th,
        locale: 'th',
        summary: "Thai",
        language: "Thai"
    },
    {
        dateFnsLocale: tr,
        locale: 'tr',
        summary: "Turkish",
        language: "Turkish"
    },
    // {
    //     dateFnsLocale: ug,
    //     locale: 'ug',
    //     summary: "Uighur",
    //     language: "Uighur"
    // },
    {
        dateFnsLocale: uk,
        locale: 'uk',
        summary: "Ukrainian",
        language: "Ukrainian"
    },
    {
        dateFnsLocale: uz,
        locale: 'uz',
        summary: "Uzbek",
        language: "Uzbek"
    },
    // {
    //     dateFnsLocale: uzCyrl,
    //     locale: 'uz-Cyrl',
    //     summary: "Uzbek Cyrillic",
    //     language: "Uzbek"
    // },
    {
        dateFnsLocale: vi,
        locale: 'vi',
        summary: "Vietnamese (Vietnam)",
        language: "Vietnamese"
    },
    {
        dateFnsLocale: zhCN,
        locale: 'zh-CN',
        summary: "Chinese Simplified (Mainland China)",
        language: "Chinese Simplified"
    },
    {
        dateFnsLocale: zhHK,
        locale: 'zh-HK',
        summary: "Chinese Traditional (Hong Kong)",
        language: "Chinese Traditional"
    },
    {
        dateFnsLocale: zhTW,
        locale: 'zh-TW',
        summary: "Chinese Traditional (Taiwan)",
        language: "Chinese Traditional"
    }
];

export const LocaleToSubset = {
    // Arabic script
    'ar-SA': 'arabic',
    'fa-IR': 'arabic',
    'ur-PK': 'arabic',
    'ps-AF': 'arabic',
  
    // Cyrillic script
    'ru-RU': 'cyrillic',
    'uk-UA': 'cyrillic',
    'bg-BG': 'cyrillic',
    'sr-RS': 'cyrillic',
  
    // Greek script
    'el-GR': 'greek',
  
    // Hebrew script
    'he-IL': 'hebrew',
  
    // Latin script
    'en-US': 'latin',
    'en-GB': 'latin',
    'fr-FR': 'latin',
    'de-DE': 'latin',
    'es-ES': 'latin',
    'it-IT': 'latin',
    'pt-PT': 'latin',
    'pt-BR': 'latin',
    'nl-NL': 'latin',
    'sv-SE': 'latin',
    'da-DK': 'latin',
    'fi-FI': 'latin',
    'no-NO': 'latin',
    'pl-PL': 'latin',
    'cs-CZ': 'latin',
    'hu-HU': 'latin',
    'ro-RO': 'latin',
    'sk-SK': 'latin',
    'sl-SI': 'latin',
    'hr-HR': 'latin',
    'lt-LT': 'latin',
    'lv-LV': 'latin',
    'et-EE': 'latin',
  
    // Latin extended script
    'vi-VN': 'vietnamese',
  
    // Chinese scripts
    'zh-CN': 'chinese-simplified',
    'zh-TW': 'chinese-traditional',
    'zh-HK': 'chinese-traditional',
  
    // Japanese script
    'ja-JP': 'japanese',
  
    // Korean script
    'ko-KR': 'korean',
  
    // Thai script
    'th-TH': 'thai',
  
    // Additional scripts
    'hi-IN': 'latin',  // Hindi primarily uses Devanagari script but Google Fonts might not have a specific subset
    'bn-BD': 'latin', // Bengali primarily uses Bengali script but might use latin in Google Fonts
    'ta-IN': 'latin', // Tamil primarily uses Tamil script but might use latin in Google Fonts
    'te-IN': 'latin', // Telugu primarily uses Telugu script but might use latin in Google Fonts
    'ml-IN': 'latin', // Malayalam primarily uses Malayalam script but might use latin in Google Fonts
    'kn-IN': 'latin', // Kannada primarily uses Kannada script but might use latin in Google Fonts
    'gu-IN': 'latin', // Gujarati primarily uses Gujarati script but might use latin in Google Fonts
  };

export const DateFormatList: string[] = [
    'PPPP',
    'PPP',
    'PP',
    'P',
    'do MMM yyyy, EEEE',
    'do MMM yyyy',
    'MMM do yyyy, EEEE',
    'MMM do yyyy',
    'yyyy MMM do, EEEE',
    'yyyy MMM do',
];

export const TimeFormatList: string[] = [
    'pppp',
    'ppp',
    'pp',
    'p',
    'h:mm a',
    'HH:mm',
    'h:mm a z',
    'HH:mm z',
    'z h:mm a',
    'z HH:mm',
];
